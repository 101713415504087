import { Poppins, Darker_Grotesque } from 'next/font/google';
import clsx from 'clsx';

import styles from './text.module.scss';

export const poppins = Poppins({
  variable: '--poppins-font',
  weight: ['400', '600', '700'],
  subsets: ['latin'],
});

export const darkerGrotesque = Darker_Grotesque({
  variable: '--darker-font',
  weight: ['500', '700'],
  subsets: ['latin'],
});

export const BodyText = ({
  children,
  parentStyles,
}: {
  children: React.ReactNode;
  parentStyles?: string;
}) => (
  <p className={clsx(styles.bodyText, parentStyles && parentStyles)}>
    {children}
  </p>
);

export const H1 = ({
  children,
  parentStyles,
}: {
  children: React.ReactNode;
  parentStyles?: string;
}) => (
  <h1
    className={clsx(styles.h1, poppins.className, parentStyles && parentStyles)}
  >
    {children}
  </h1>
);

export const H2 = ({
  children,
  parentStyles,
}: {
  children: React.ReactNode;
  parentStyles?: string;
}) => (
  <h2
    className={clsx(styles.h2, poppins.className, parentStyles && parentStyles)}
  >
    {children}
  </h2>
);

export const H3 = ({
  children,
  parentStyles,
}: {
  children: React.ReactNode;
  parentStyles?: string;
}) => (
  <h3
    className={clsx(styles.h3, poppins.className, parentStyles && parentStyles)}
  >
    {children}
  </h3>
);

export const H4 = ({
  children,
  parentStyles,
}: {
  children: React.ReactNode;
  parentStyles?: string;
}) => (
  <h4
    className={clsx(styles.h4, poppins.className, parentStyles && parentStyles)}
  >
    {children}
  </h4>
);

export const IntroText = ({
  children,
  align,
  cssClass,
}: {
  children: React.ReactNode;
  align?: string;
  cssClass?: string;
}) => (
  <p
    className={clsx(
      styles.introText,
      align === 'center' && styles.center,
      cssClass && cssClass
    )}
  >
    {children}
  </p>
);

export const Small = ({
  children,
  parentStyles,
}: {
  children: React.ReactNode;
  parentStyles?: string;
}) => (
  <span className={clsx(styles.small, parentStyles && parentStyles)}>
    {children}
  </span>
);

export const Em = ({
  children,
  parentStyles,
}: {
  children: React.ReactNode;
  parentStyles?: string;
}) => (
  <em className={clsx(styles.em, parentStyles && parentStyles)}>{children}</em>
);
