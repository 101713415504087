import * as React from 'react';
import Link from 'next/link';
import { BodyText, H1, H2, H3, Em } from '@/components/styled/text';
import { DefaultList, BasicListItem } from '@/components/styled/list';
import { linkResolver } from '../prismic-configuration';
import { EmbeddedImage } from './components/Image';

function renderLink({ node, key, children }) {
  const targetAttr = node.data.target ? { target: node.data.target } : {};
  const relAttr = node.data.target ? { rel: 'noopener' } : {};
  const url = node.data.url || linkResolver(node.data);
  const anchorProps = { ...targetAttr, ...relAttr };

  if (node.link_type === 'Web') {
    <a href={url} {...anchorProps}>
      {children}
    </a>;
  }

  return (
    <Link href={url} key={key}>
      {children}
    </Link>
  );
}

function renderSpan(text: string, key: string | number) {
  const result: React.ReactNode[] = [];

  let i = 0;
  for (const line of text.split('\n')) {
    if (i > 0) {
      result.push(<br key={`${i}__break`} />);
    }
    result.push(<React.Fragment key={`${i}__line`}>{line}</React.Fragment>);
    i++;
  }

  return <React.Fragment key={key}>{result}</React.Fragment>;
}

const defaultComponents = {
  heading1: ({ children }) => <H1>{children}</H1>,
  heading2: ({ children }) => <H2>{children}</H2>,
  heading3: ({ children }) => <H3 parentStyles="test">{children}</H3>,
  paragraph: ({ children }) => <BodyText>{children}</BodyText>,
  image: ({ node }) => <EmbeddedImage node={node} />,
  strong: ({ children }) => <strong>{children}</strong>,
  em: ({ children }) => <Em>{children}</Em>,
  hyperlink: ({ node, key, children }) => renderLink({ node, key, children }),
  list: ({ children }) => <DefaultList>{children}</DefaultList>,
  listItem: ({ children, key }) => {
    return <BasicListItem key={key}>{children}</BasicListItem>;
  },
  span: ({ text, key }) => renderSpan(text, key),
};

export { defaultComponents, renderLink, renderSpan };
