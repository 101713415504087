import Image from "next/legacy/image";
import clsx from 'clsx';
import { linkResolver } from '../../prismic-configuration';
import classes from './image.module.scss';

export const EmbeddedImage = ({ node }) => {
  const linkUrl = node.linkTo ? linkResolver(node.linkTo) : null;
  const linkTarget =
    node.linkTo && node.linkTo.target ? { target: node.linkTo.target } : {};
  const linkRel = linkTarget.target ? { rel: 'noopener' } : {};
  const wrapperClassList = [node.label || '', 'block-img'];
  const img = (
    <Image
      src={node.url}
      width={node.dimensions.width}
      height={node.dimensions.height}
      alt={node.alt || ''}
      className={classes.imageNode}
    />
  );

  return (
    <div className={clsx(wrapperClassList)}>
      {linkUrl ? (
        <a {...linkTarget} {...linkRel} href={linkUrl}>
          {img}
        </a>
      ) : (
        img
      )}
    </div>
  );
};
