'use client';

import React from 'react';
import { PrismicProvider } from '@prismicio/react';
import Link from 'next/link';

import { UserProvider } from '@/context/user-context';
import { defaultComponents } from '@/lib/cms/serializers/default';
import { CountriesProvider } from '@/context/countries';

function AppProviders({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement {
  return (
    <CountriesProvider>
      <PrismicProvider
        richTextComponents={defaultComponents}
        internalLinkComponent={props => <Link {...props} />}
      >
        <UserProvider>{children}</UserProvider>
      </PrismicProvider>
    </CountriesProvider>
  );
}

export default AppProviders;
