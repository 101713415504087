export function post(endpoint: string, payload) {
  return fetch(`/api/${endpoint}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

export async function get<T>(endpoint: string): Promise<T> {
  const response = await fetch(`/api/${endpoint}`);
  const data = await response.json();

  return data;
}

export async function patch<T>(url: string, body: unknown): Promise<T> {
  try {
    const response = await fetch(`/api/${url}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (response.ok) {
      return await response.json();
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function clientGet(endpoint: string): Promise<unknown> {
  const response = await fetch(`/api/${endpoint}`);

  return response.json();
}

export async function fetcher<JSON = unknown>(url: string): Promise<JSON> {
  const res = await fetch(url);
  if (res.status >= 300) {
    throw new Error('API Client error');
  }
  return res.json();
}
