import { PrismicDocument } from '@prismicio/client';
import { CMSLink } from './types';

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
// Configure your site's access point here
export const apiEndpoint = 'https://annaandco.cdn.prismic.io/api/v2';

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = '';

export function linkResolver(doc: PrismicDocument | CMSLink): string {
  if (doc.type === 'page') {
    return `/${doc.uid}`;
  }

  if (doc.type === 'post') {
    return `/post/${doc.uid}`;
  }

  if (doc.type === 'infoproducto' && doc.tags.includes('course')) {
    return `/cursos/${doc.uid}`;
  }

  if (doc.type === 'infoproducto' && doc.tags.includes('guide')) {
    return `/guias/${doc.uid}`;
  }

  if (doc.type === 'infoproducto' && doc.tags.includes('class')) {
    return `/clases/${doc.uid}`;
  }

  if (doc.type === 'uadbec_page') {
    return '/bienestar-en-casa';
  }

  return '/';
}
// export function hrefResolver(doc: PrismicDocument): string {
//   if (doc.type === 'post') {
//     return '/post/[slug]';
//   }

//   if (doc.type === 'infoproducto') {
//     return '/guias/[slug]';
//   }

//   if (doc.type === 'uadbec_page') {
//     return '/bienestar-en-casa';
//   }

//   return '/';
// }
