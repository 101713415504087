'use client';

import * as React from 'react';
import { useRouter } from 'next/navigation';
import useSWR from 'swr';
import { fetcher } from '../utils/clientApi';

type Action =
  | { type: 'login'; user: UserState['user'] }
  | { type: 'setError'; error: UserState['error'] | undefined }
  | { type: 'logout' };

type Dispatch = (action: Action) => void;

interface UserDispatch {
  dispatch: Dispatch;
  revalidateUser: (
    data?: User | Promise<User>,
    shouldRevalidate?: boolean
  ) => Promise<User | undefined>;
}

export interface UserProfile {
  email: string;
  firstName: string;
  lastName?: string;
  country?: string;
  picture?: string;
  acId?: string;
  billing?: any;
}

export interface User {
  id: string;
  profile: UserProfile;
  products?: string[];
  emailVerified?: boolean;
}

interface Redirects {
  redirectTo?: string;
  redirectIfFound?: boolean;
}

interface UserState {
  user: User | undefined;
  error?: {
    message: string;
  };
}

const UserContext = React.createContext<UserState | undefined>(undefined);
const UserDispatchContext = React.createContext<UserDispatch | undefined>(
  undefined
);

function userReducer(state: UserState, action: Action): UserState {
  switch (action.type) {
    case 'login': {
      return { ...state, user: action.user, error: undefined };
    }
    case 'logout':
      return { user: undefined, error: { message: 'User logged out' } };
    case 'setError':
      return { ...state, error: action.error };
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

const UserProvider = ({
  children,
}: {
  children: React.ReactNode;
}): React.ReactElement => {
  const [state, dispatch] = React.useReducer(userReducer, { user: undefined });
  const { mutate } = useSWR<UserState['user']>('/api/profile', fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
    shouldRetryOnError: false,
    onSuccess: data => {
      dispatch({ type: 'login', user: data });
    },
    onError: error => {
      dispatch({ type: 'setError', error });
    },
  });

  return (
    <UserContext.Provider value={state}>
      <UserDispatchContext.Provider
        value={{ dispatch, revalidateUser: mutate }}
      >
        {children}
      </UserDispatchContext.Provider>
    </UserContext.Provider>
  );
};

function useUser({
  redirectTo = '',
  redirectIfFound = false,
}: Redirects = {}): UserState {
  const context = React.useContext(UserContext);
  const router = useRouter();

  React.useEffect(() => {
    if (!redirectTo || (!context?.user && !context?.error)) return;

    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !context.user) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && context.user)
    ) {
      router.replace(redirectTo);
    }
  }, [context, redirectTo, redirectIfFound, router]);

  if (typeof context === 'undefined') {
    throw new Error('useUser must be used within a UserProvider');
  }

  return context;
}

function useUserDispatch(): UserDispatch {
  const context = React.useContext(UserDispatchContext);
  if (typeof context === 'undefined') {
    throw new Error('useUserDispatch must be used within a UserProvider');
  }
  return context;
}

export { UserProvider, UserContext, useUser, useUserDispatch };
