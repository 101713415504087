import * as React from 'react';
import countries, { LocalizedCountryNames } from 'i18n-iso-countries';
import es from 'i18n-iso-countries/langs/es.json';

interface CountriesState {
  names: LocalizedCountryNames<{
    select: 'official';
  }>;
}

const CountriesContext = React.createContext<CountriesState | undefined>(
  undefined
);

const CountriesProvider = ({ children }) => {
  countries.registerLocale(es);
  const names = countries.getNames('es');
  return (
    <CountriesContext.Provider value={{ names }}>
      {children}
    </CountriesContext.Provider>
  );
};

function useCountriesData() {
  const context = React.useContext(CountriesContext);

  if (context === undefined) {
    throw new Error('useCountriesData must be used within a CountriesProvider');
  }

  return context;
}

export { CountriesProvider, useCountriesData };
